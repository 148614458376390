import dynamic from 'next/dynamic';

export * from './layout.typing';

export { default as DefaultLayout } from './DefaultLayout';
export { default as DashboardLayout } from './dashboard/DashboardLayout';
export { default as Error } from './error/Error';
export { default as EmptyData } from './EmptyData';
export { ConfirmationLayout } from './confirmation-layout';
export const LoadingScreen = dynamic(() => import('./LoadingScreen'));
export const EmptyResult = dynamic(() => import('./EmptyResult'));
export { default as MobilePermission } from './MobilePermission';
