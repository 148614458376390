import { ChatUsDialogContent } from '@elements';
import { useAuth, usePermission } from '@hooks';
import bolyMaintenance from '@public/images/svg/boly-maintenance.svg';
import bolyNotFound from '@public/images/svg/boly-not-found.svg';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import { screenSize } from '@topremit/shared-web/common/size';
import { Button, parseHtml } from '@topremit/shared-web/components/elements';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import { NativeWebViewBridgeEventName } from '@topremit/shared-web/typings/native-web-view-bridge.model';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

import ErrorContent from './ErrorContent';
import ErrorDialog from './ErrorDialog';

interface ErrorProps {
  statusCode: number;
  description?: string;
}

function Error({ statusCode, description }: ErrorProps) {
  const { t } = useTranslation('error');
  const router = useRouter();
  const isMobileSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);
  const { isAuthenticated } = useAuth();
  const { isPersonalAccount } = usePermission();

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  function handleButtonError() {
    if (isNativeApp()) {
      return sendNativeMessage({
        name: NativeWebViewBridgeEventName.RELOAD,
      });
    }
    router.reload();
  }

  function handleNativeAppBack(e) {
    if (isNativeApp()) {
      e.preventDefault();
      sendNativeMessage({ name: NativeWebViewBridgeEventName.BACK });
    }
  }

  function handleGoToHome(e) {
    router.push('/home');
    handleNativeAppBack(e);
  }

  function handleGoToMainPage(e) {
    router.push('/');
    handleNativeAppBack(e);
  }

  function handleButton404() {
    if (isAuthenticated) {
      if (isPersonalAccount) {
        return (
          <Button onClick={handleGoToHome} fullWidth={isMobileSize}>
            {t('go_to_home')}
          </Button>
        );
      } else {
        return (
          <Button
            onClick={() => router.push('/profile')}
            fullWidth={isMobileSize}
          >
            {t('go_to_profile')}
          </Button>
        );
      }
    }
    return (
      <Button onClick={handleGoToMainPage} fullWidth={isMobileSize}>
        {t('go_to_main_page')}
      </Button>
    );
  }

  function handleButtonStoragePermission() {
    setIsErrorDialogOpen(true);
  }

  // Note: this part causing Loop when some error pop up in production
  // useEffect(() => {
  //   if (router.isReady && lang === 'default') {
  //     const locale = getItemInCookies('NEXT_LOCALE') || 'en';
  //     router.replace(`${window.location.origin}/${locale}/${router.asPath}`);
  //   }
  // }, [router.isReady, lang]);

  // if (!router.isReady || lang === 'default') {
  //   return null;
  // }

  const isStorageDisabled =
    typeof window !== 'undefined'
      ? localStorage?.isDisabled?.() ?? false
      : false;

  if (isStorageDisabled) {
    return (
      <>
        <ErrorContent
          headerTitle={t('topremit_down')}
          contentTitle={t('storage_permission.title')}
          description={t('storage_permission.subtitle')}
          actionButton={
            <Button
              onClick={handleButtonStoragePermission}
              fullWidth={isMobileSize}
            >
              {t('chat_us')}
            </Button>
          }
          illustration={
            <Image
              src={bolyNotFound}
              alt="error 500"
              width={320}
              height={320}
            />
          }
        />
        <ErrorDialog
          isOpen={isErrorDialogOpen}
          body={
            <ChatUsDialogContent
              prefillType="storage_permission"
              onClick={() => setIsErrorDialogOpen(false)}
            />
          }
          onClose={() => setIsErrorDialogOpen(false)}
        />
      </>
    );
  }

  switch (statusCode) {
    case 404:
      return (
        <ErrorContent
          headerTitle={t('page_not_found')}
          contentTitle={parseHtml(t('404.title'))}
          description={description || t('error:404.body')}
          actionButton={handleButton404()}
          illustration={
            <Image
              src={bolyNotFound}
              alt="boly not found"
              width={isMobileSize ? 180 : 320}
              height={isMobileSize ? 180 : 320}
            />
          }
        />
      );
    case 500:
      return (
        <ErrorContent
          headerTitle={t('topremit_down')}
          contentTitle={t('500.title')}
          description={description || t('500.body')}
          actionButton={
            <Button onClick={handleButtonError} fullWidth={isMobileSize}>
              {t('refresh')}
            </Button>
          }
          illustration={
            <Image
              src={bolyMaintenance}
              alt="error 500"
              width={isMobileSize ? 180 : 320}
              height={isMobileSize ? 180 : 320}
            />
          }
        />
      );
    default:
      return null;
  }
}

export default Error;
