import { css, cx } from '@emotion/css';
import { IEmptyData } from '@modules/dashboard/activity/type';
import emptyStateBolyImage from '@public/images/svg/empty-state-boly.svg';
import Image from 'next/image';

export default function EmptyData({
  className,
  title,
  description,
  width = 200,
  height = 200,
  titleSize = 'default',
}: IEmptyData) {
  return (
    <div className={cx(styled.root, className)}>
      <Image
        src={emptyStateBolyImage}
        width={width}
        height={height}
        alt="empty-state-boly"
        priority
        placeholder="blur"
        blurDataURL="/images/png/empty-state-boly-blur.png"
      />
      <span
        className={cx('title', {
          default: titleSize === 'default',
          small: titleSize === 'small',
        })}
      >
        {title}
      </span>
      <span className="description sm-text">{description}</span>
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .title {
      text-align: center;
      color: var(--text-primary);
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      font-weight: var(--bold-font-weight);
    }
    > .description {
      text-align: center;
    }
  `,
  default: css`
    font-size: 1.25rem !important;
    line-height: 1.75rem;
  `,
  small: css`
    font-size: 1rem !important;
  `,
};
