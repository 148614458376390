import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Container,
  Flex,
  Text,
} from '@topremit/shared-web/components/elements';
import Head from 'next/head';
import { ReactNode } from 'react';

const { tabletMd } = screenSize;

interface IErrorContent {
  headerTitle: string;
  contentTitle: string | ReactNode;
  description: string;
  actionButton: ReactNode;
  illustration: ReactNode;
}

function ErrorContent({
  headerTitle,
  contentTitle,
  description,
  actionButton,
  illustration,
}: IErrorContent) {
  return (
    <>
      <Head>
        <title>{headerTitle}</title>
      </Head>
      <section className={styled.root}>
        <Container>
          <Flex className="inner-container">
            <Flex column className="content">
              <Text minSize="h5" maxSize="h3" className="title">
                {contentTitle}
              </Text>
              <Text className="desc">{description}</Text>
              {actionButton}
            </Flex>
            <Flex className="illustration">{illustration}</Flex>
          </Flex>
        </Container>
      </section>
    </>
  );
}

const styled = {
  root: css`
    .container {
      padding-top: 10rem;
      padding-bottom: 10rem;
      .inner-container {
        align-items: center;
        justify-content: center;
        @media (max-width: ${tabletMd}px) {
          flex-direction: column-reverse;
          align-items: center;
          padding: 0 1.25rem;
        }
        .content {
          max-width: 42.5rem;
          margin-right: 7.5rem;
          @media (max-width: ${tabletMd}px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-left: auto;
          }
          .title {
            font-weight: var(--black-font-weight);
            text-transform: uppercase;
            margin-bottom: 0.5rem;
          }
          .desc {
            color: var(--neutral-500);
            margin-bottom: 2rem;
          }
        }
        .illustration {
          max-width: 20rem;
          justify-content: flex-end;
          @media (max-width: ${tabletMd}px) {
            max-width: unset;
            justify-content: center;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  `,
};

export default ErrorContent;
